import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCJLK1hLq4YLgAVIX-KmxDXk8MqiojG410", 
  authDomain: "dummy-cdec2.firebaseapp.com",
  projectId: "dummy-cdec2",
  storageBucket: "dummy-cdec2.appspot.com",
  messagingSenderId: "580203112858",
  appId: "1:580203112858:web:d9143320cf014cba7b7223",
};

const app = getApp.length > 0 ? getApp() : initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { app, firestore, storage };
