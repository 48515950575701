import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "./Footer";
import NavbarService from "../Navbar/NavbarService";
import "./Contact.css";
import BassURl from "../Api/Api";
import { FaPhoneAlt, FaRegClock,FaPhoneSquare, FaPhone, FaEnvelope,FaMapMarkerAlt, FaMobileAlt} from "react-icons/fa";
import { Ri24HoursFill } from "react-icons/ri";


function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BassURl}api/contact-enquiry`,
        formData
      );

      // Handle successful form submission here
      console.log("Form submitted successfully");
      window.location.href = "/thank-you";
      // Optionally, reset the form fields
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error here
    }
  };

  return (
    <>
      <NavbarService />
      <img
        loading="lazy"
        src="/assets/img/about-hero.jpg"
        alt=""
        width="100%"
        style={{ height: "90vh", marginBottom: "25px" }}
      />

      {/* <div
                className="image-cover hero_banner "
                style={{ background: "url(/assets/img/uni-banner.jpg) no-repeat" }}
                data-overlay={4}
            ></div> */}
      <div className="contact ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-page-form">
                <h4>Feel Free To Make A Call Request Now</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Subject"
                          name="subject"
                          value={formData.subject}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-12">
                        <textarea
                          rows={6}
                          className="form-control"
                          placeholder="Message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                        <button type="submit">Send Message</button>
                      </div>
                    </div>
                  </form>
                  
              </div>
              <div className="row pt-3">
                    <div className="col-12">
                      <div className="border p-3">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <Ri24HoursFill size={30} />
                          <div className="d-flex align-items-center flex-column">
                            <h5>Opening Hours</h5>
                            <div className="d-flex align-items-center">
                              <p>9:00 AM - 8:00 PM</p>
                            </div>
                          </div>
                        </div>
                      </div>
                   

                    </div>
                  </div>
              <div className="row">
                  <div className="col-md-6 pt-3">
                    <div className="border p-3  d-flex justify-content-center align-items-center mb-3" >
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                        <FaPhoneSquare size={20} color="#1E3A8A"  />
                        </div>
                        <div>
                          <h6 className="mb-1">Phone Number</h6>
                          <p className="mb-0">0522-4060673</p>
                        </div>
                      </div>
                    </div>
                    <div className="border p-3  d-flex justify-content-center align-items-center mb-3">
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                        <FaMobileAlt size={20} color="#1E3A8A" />
                        </div>
                        <div>
                          <h6 className="mb-1">Phone Number</h6>
                          <p className="mb-0">7388188860, 9936844403</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="border p-3 d-flex justify-content-center align-items-center mb-3">
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <FaEnvelope size={20} color="#1E3A8A" />
                        </div>
                        <div>
                          <h6 className="mb-1">Email</h6>
                          <p className="mb-0">Care@aquamart.co.in</p>
                        </div>
                      </div>
                    </div>
                    <div className="border p-3 d-flex justify-content-center align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <FaMapMarkerAlt size={20} color="#1E3A8A" />
                        </div>
                        <div>
                          <h6 className="mb-1">Address</h6>
                          <p className="mb-0">Engineering college, Lko</p>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              
            </div>
        
            <div className="col-lg-6">
              <div className="contact-text-area ">

                <div className="text-center ">
                  <h3 className="mb-3 font-weight-bold">We're Here For All Your Water Purifier Needs</h3>
                  <p className="text-muted">
                    At Aquamart, we understand that having access to clean, refreshing drinking water is essential. That's why we offer comprehensive water purifier services to ensure your system runs smoothly and delivers optimal performance.
                  </p>
                </div>
                {/* Services Section */}
                <div className="row">
                  {/* Installation */}
                  <div className="col-12 col-md-12 col-md-6">
                    <div className="card" style={{ backgroundColor: "#107cc44d", height: "auto", width: "100%" }}>
                      <div className="p-3 shadow rounded service-box">
                        <h4 className="mb-3 font-weight-bold" style={{ color: "rgb(19, 62, 106)" }}>Installation</h4>
                        <p>
                          Our certified technicians can expertly install your water purifier system, ensuring it's set up correctly for long-lasting efficiency.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Maintenance */}
                  <div className="col-12 col-md-12 col-md-6">
                    <div className="card" style={{backgroundColor:" #107cc44d",  height: "auto", width: "100%"}}>
                      <div className="p-3 shadow rounded service-box">
                        <h4 className="mb-3 font-weight-bold" style={{ color: "rgb(19, 62, 106)" }}>Maintenance</h4>
                        <p>
                          Regular maintenance is crucial for optimal water quality and system longevity. We offer flexible maintenance plans to fit your needs, with qualified technicians performing thorough cleaning, inspection, and parts replacement (if necessary).
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Repairs */}
                  <div className="col-12 col-md-12 col-md-6x">
                    <div className="card" style={{backgroundColor:" #107cc44d", height: "auto", width: "100%"}}>
                      <div className="p-3 shadow rounded service-box">
                        <h4 className="mb-3 font-weight-bold " style={{ color: "rgb(19, 62, 106)" }}>Repairs</h4>
                        <p>
                          If you encounter any issues with your water purifier, our technicians can diagnose the problem and provide prompt repairs using genuine brand spare parts.
                        </p>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ContactUs;
