import React, { useState } from 'react'
import BassURl from '../Api/Api';
import axios from 'axios';

export const MobilePopupForm = () => {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${BassURl}api/contact-enquiry`, formData);
            
            // Handle successful form submission here
            console.log('Form submitted successfully');
            window.location.href = '/thank-you';
            // Optionally, reset the form fields
            setFormData({
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: ''
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error here
        }
    };

    const modalStyle = {
        position: 'fixed',
        top: '10%',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1050,
        display: showModal ? 'block' : 'none',
        overflow: 'hidden',
        outline: 0
    };
  return (
        <>
            <button type="button" className="btn btn-primary btn-sm mt-3" onClick={handleShow}>
             Buy Now
            </button>

            {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={modalStyle}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Quick Enquiry</h5>
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className="row">
                                            <div className="col-lg-6 mb-2">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Phone"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Subject"
                                                    name="subject"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-lg-12 mb-2">
                                                <textarea
                                                    rows={6}
                                                    className="form-control"
                                                    placeholder="Message"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                
                                                />
                                            </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                                    <button type="submit" className="btn btn-primary">Save changes</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
  );
}



