import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import BassURl from "../Api/Api";
import NavbarService from "../Navbar/NavbarService";
import { addToServiceCartAction } from "../Redux/Action/CartAction";
import "./Service.css";
import ServiceAdd from "./ServiceAdd";
import parse from "html-react-parser";
import { PopupForm } from "../Model/PopupForm";
import ServiceDetailPopup from "../Component/ServiceDetailPopup";
import ProductAddButton from "../Component/ProductAddButton";
import Footer from "../Component/Footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function Service() {
  const param = useParams();
  const [GetServiceCatgory, setGetServiceCatgory] = useState();
  useEffect(() => {
    axios
      .get(`${BassURl}api/service-category`)
      .then((res) => {
        setGetServiceCatgory(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [serviceByCategory, setServiceByCategory] = useState("");

  useEffect(() => {
    axios
      .get(`${BassURl}api/service-by-category/${param.service_id}`)
      .then((res) => {
        setServiceByCategory(res.data.data.data);
        console.log(res.data.data.data);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }, []);

  const [CategoryDetail, setCategoryDetail] = useState(null);
  useEffect(() => {
    axios
      .get(`${BassURl}api/service-category-details/${param.service_id}`)
      .then((res) => {
        setCategoryDetail(res.data);
        console.log(res.data);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }, []);

  const addToServiceCartReducer = useSelector(
    (state) => state?.addToServiceCartReducer
  );
  const { cartItemService } = addToServiceCartReducer;
  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };
  function removeNonBreakingSpaces(inputString) {
    return inputString.replace(/&nbsp;/g, " ");
  }
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  // Jagriti Start
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedFilters, setSelectedFilters] = useState("All");
  const [inputText, setInputText] = useState("");
  const [filters, setFilters] = useState({
    color: "all",
    store: "all",
    category: [],
    rating: "all",
    price: "",
  });

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleFilterSelection = (type, value) => {
    setSelectedFilter(value);
    setFilterOpen(false);
    setFilters({ ...filters, [type]: value });
  };

  // Rating Start
  const handleFilterSelections = (type, value) => {
    setSelectedFilters(value);
    setFilterOpen(false);
    setFilters({ ...filters, [type]: value });
  };
  // Rating End

  const handleInputChange = (e) => {
    setInputText(e.target.value.toLowerCase());
  };

  const handleCategoryChange = (e) => {
    const { checked, value } = e.target;
    let updatedCategories = [...filters.category];

    if (checked && !updatedCategories.includes(value)) {
      updatedCategories.push(value);
    } else {
      updatedCategories = updatedCategories.filter(
        (category) => category !== value
      );
    }

    setFilters({ ...filters, category: updatedCategories });
  };
  // Jagriti End

  // Priyanka Start
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };
  // Priyanka End

  // Jyoti Start
  const [minAngle, setMinAngle] = useState(10);
  const [maxAngle, setMaxAngle] = useState(30);

  const handleMinChange = (event) => {
    let val = parseInt(event.target.value);
    if (val > maxAngle) {
      setMaxAngle(val);
    }
    setMinAngle(val);
  };

  const handleMaxChange = (event) => {
    let val = parseInt(event.target.value);
    if (val < minAngle) {
      setMinAngle(val);
    }
    setMaxAngle(val);
  };
  // Jyoti End

  const [ProductDetail, setProductDetail] = useState(null);
  useEffect(() => {
    axios
      .get(`${BassURl}api/shop-category-details/${param.id}`)
      .then((res) => {
        setProductDetail(res.data);
        console.log(res.data);
      })
      .catch((erro) => {
        console.log(erro);
      });
    // console.log(serviceByCategory);
  }, []);

  return (
    <>
      <NavbarService />

      <div
        style={{
          height: "90vh",
        }}
      >
        {CategoryDetail && (
          // Render actual data when CategoryDetail is truthy
          <img
            loading="lazy"
            src={`${BassURl}${CategoryDetail.data.service_background}`}
            width="100%"
            alt="Category Image"
          />
        )}
      </div>

      <section className="container p-0">
        <div className="mt-5 service-tab">
          <Tabs className="">
            <TabList
              className="container-fluid-lg p-4 flex justify-content-center "
              style={{
                gap: "0px 20px",
              }}
            >
              <Tab>Repairing</Tab>
              <Tab>Service</Tab>
              <Tab>Installation</Tab>
            </TabList>

            <TabPanel className="container-fluid-lg">
              <>
                <div className="d-flex  service-card-1 m-4">
                  <div className="p-4">
                    <img
                      loading="lazy"
                      src="/assets/img/repair.webp"
                      style={{
                        width: "300px",
                      }}
                      className="rounded-5"
                    />
                  </div>
                  <div className="p-4">
                    <h3>RO Repairing</h3>
                    <p>
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled Lorem Ipsum is
                      simply dummy text of the printing and it to make a type
                      specimen book. Lorem Ipsum
                    </p>
                    <h4>Rating</h4>
                    <h4>Price : 999</h4>
                    <h4>timing</h4>
                    <ProductAddButton />
                  </div>
                </div>
              </>
            </TabPanel>
            <TabPanel className="container-fluid-lg">
              <div className="d-flex  service-card-1 m-4">
                <div className="p-4">
                  <img
                    loading="lazy"
                    src="/assets/img/Service.jpeg"
                    style={{
                      width: "300px",
                    }}
                    className="rounded-5"
                  />
                </div>
                <div className="p-4">
                  <h3>RO Service</h3>
                  <p>
                    industry. Lorem Ipsum has been the industry's standard dummy
                    text ever since the 1500s, when an unknown printer took a
                    galley of type and Lorem Ipsum is simply dummy text of the
                    printing and typesetting scrambled it to make a type
                    specimen book. Lorem Ipsum
                  </p>
                  <h4>Rating</h4>
                  <h4>Price</h4>
                  <h4>timing</h4>
                  <ProductAddButton />
                </div>
              </div>
            </TabPanel>
            <TabPanel className="container-fluid-lg">
              <div className="d-flex  service-card-1 m-4">
                <div className="p-4">
                  <img
                    loading="lazy"
                    src="/assets/img/install.webp"
                    style={{
                      width: "300px",
                    }}
                    className="rounded-5"
                  />
                </div>
                <div className="p-4">
                  <h3>RO installation</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. Lorem Ipsum
                  </p>
                  <h4>Rating</h4>
                  <h4>Price</h4>
                  <h4>timing</h4>
                  <ProductAddButton />
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </section>

      {/* <section
        className="section-b-space shop-section"
        style={{
          background: "skyblue",
        }}
      >
        <div className="container-fluid-lg">
          <div className="">
            <div className="d-flex  service-card-1 m-4">
              <div className="p-4">
                <img loading="lazy"
                  src="/assets/img/b-5.jpg"
                  style={{
                  width:"300px"}}
                  className="rounded-5"
                />
              </div>
              <div className="p-4">
                <h3>RO Repairing</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. Lorem Ipsum
                </p>
                <h4>Rating</h4>
                <h4>Price</h4>
                <h4>timing</h4>
                <ProductAddButton />
              </div>
            </div>
            <div className="d-flex  service-card-1 m-4">
              <div className="p-4">
                <img loading="lazy"
                  src="/assets/img/b-5.jpg"
                  style={{
                  width:"300px"}}
                  className="rounded-5"
                />
              </div>
              <div className="p-4">
                <h3>RO installation</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. Lorem Ipsum
                </p>
                <h4>Rating</h4>
                <h4>Price</h4>
                <h4>timing</h4>
                <ProductAddButton />
              </div>
            </div>
            <div className="d-flex  service-card-1 m-4">
              <div className="p-4">
                <img loading="lazy"
                  src="/assets/img/b-5.jpg"
                  style={{
                  width:"300px"}}
                  className="rounded-5"
                />
              </div>
              <div className="p-4">
                <h3>RO Service</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. Lorem Ipsum
                </p>
                <h4>Rating</h4>
                <h4>Price</h4>
                <h4>timing</h4>
                <ProductAddButton />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
      {/* {
    GetServiceCatgory && GetServiceCatgory?.map((data)=>{
      if(data?.service_url === param?.service_url){

      }
    })
   } */}
    </>
  );
}

export default Service;
