import axios from "axios";
import React, { useEffect, useState } from "react";
import NavbarService from "../Navbar/NavbarService";
import BassURl from "../Api/Api";
import Footer from "./Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopProduct from "../Slider/NewTopProduct";
import { NavLink, useParams } from "react-router-dom";
import NewTopProduct from "../Slider/NewTopProduct";
import { isMobile } from "react-device-detect";

import Navbar from "../Navbar/Navbar";
import CartItem from "../Cart/CartItem";
import CartService from "../Cart/CartService";
import ProductAddButton from "./ProductAddButton";
// import ServiceAdd from './ServiceAdd';
import parse from "html-react-parser";
import { PopupForm } from "../Model/PopupForm";
function ServiceVew() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    const params = useParams();
    const [service, setService] = useState(null);
    useEffect(() => {
        axios
            .get(`${BassURl}/web-api/all-service-category`)
            .then((response) => {
                setService(response.data.data);
            });
    }, []);

    // api call
    const [testimonial, setTestimonial] = useState([]);
    useEffect(() => {
        axios.get(`${BassURl}web-api/all-testimonial`).then((response) => {
            setTestimonial(response.data.data);
        });
    }, []);
    // const [{ user, cartShow, cartShowToast, cartItems }, dispatch] =
    //     useStateValue();
    const [items, setItems] = useState([]);

    // api call
    const [product, setProduct] = useState(null);
    useEffect(() => {
        axios.get(`${BassURl}/api/top-product`).then((response) => {
            setProduct(response.data.data);
        });
    }, []);

    // const addtocart = (dt) => {
    //     dispatch({
    //         type: actionType.SET_CARTITEMS,
    //         cartItems: items,
    //     });

    //     localStorage.setItem("cartItems", JSON.stringify(items));
    // };

    // useEffect(() => {
    //     addtocart();
    // }, [items]);

    return (
        <>
            {product?.map((data, index) => {
                if (data?.product_url === params?.product_url) {
                    return (
                        <>
                            <Navbar />
                            <div
                                className="image-cover hero_banner "
                                style={{
                                    background:
                                        "url(/assets/img/banner/banner.webp) no-repeat",
                                }}
                                // style={{
                                //     background:
                                //         "url(https://aquamartadmin.digitalnawab.com/assets/uploads/category/thumbnail_images/1701349880WEB-2.webp) no-repeat",
                                // }}
                                data-overlay={4}
                                key={index}
                            ></div>
                            <section
                                className="inner-section"
                                style={{ background: "#80808017" }}
                            >
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="details-gallery">
                                                <ul className="details-preview">
                                                    <div>
                                                        <img loading="lazy"
                                                            src={
                                                                BassURl +
                                                                data?.product_image
                                                            }
                                                            style={{
                                                                margin: "75px 0 12px 92px",
                                                                width: "55%",
                                                            }}
                                                            alt="product"
                                                        />
                                                    </div>
                                                    {/* <li>
                                                        <img loading="lazy"
                                                            src={
                                                                BassURl +
                                                                data?.product_image
                                                            }
                                                            style={{ width: "22%",
                                                                margin:"8px"
                                                             }}
                                                            alt="product"
                                                        /> 
                                                        <img loading="lazy"
                                                            src={
                                                                BassURl +
                                                                data?.product_image
                                                            }
                                                            style={{ width: "22%",
                                                            margin:"8px" }}
                                                            alt="product"
                                                        />
                                                        <img loading="lazy"
                                                            src={
                                                                BassURl +
                                                                data?.product_image
                                                            }
                                                            style={{ width: "22%",
                                                            margin:"8px" }}
                                                            alt="product"
                                                        /> 
                                                        <img loading="lazy"
                                                            src={
                                                                BassURl +
                                                                data?.product_image
                                                            }
                                                            style={{ width: "22%",
                                                            margin:"8px" }}
                                                            alt="product"
                                                        /> 
                                                    </li> */}
                                                    <Slider {...settings}>
                                                        <div>
                                                            <img loading="lazy"
                                                                src={
                                                                    BassURl +
                                                                    data?.product_image
                                                                }
                                                                style={{
                                                                    width: "70%",
                                                                    margin: "8px",
                                                                }}
                                                                alt="product"
                                                            />
                                                        </div>
                                                        <div>
                                                            <img loading="lazy"
                                                                src={
                                                                    BassURl +
                                                                    data?.product_image
                                                                }
                                                                style={{
                                                                    width: "70%",
                                                                    margin: "8px",
                                                                }}
                                                                alt="product"
                                                            />
                                                        </div>
                                                        <div>
                                                            <img loading="lazy"
                                                                src={
                                                                    BassURl +
                                                                    data?.product_image
                                                                }
                                                                style={{
                                                                    width: "70%",
                                                                    margin: "8px",
                                                                }}
                                                                alt="product"
                                                            />
                                                        </div>
                                                        <div>
                                                            <img loading="lazy"
                                                                src={
                                                                    BassURl +
                                                                    data?.product_image
                                                                }
                                                                style={{
                                                                    width: "70%",
                                                                    margin: "8px",
                                                                }}
                                                                alt="product"
                                                            />
                                                        </div>
                                                    </Slider>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="details-content">
                                                <h3 className="details-name">
                                                    <NavLink to="#">
                                                        {data?.product_title}
                                                    </NavLink>
                                                </h3>
                                                <div className="details-meta">
                                                    <p>
                                                        SKU:<span>1234567</span>
                                                    </p>
                                                    <p>
                                                        BRAND ID:
                                                        <NavLink to="#">
                                                            {data?.brand_id}
                                                        </NavLink>
                                                    </p>
                                                </div>
                                                <div className="details-rating">
                                                    <i className="active icofont-star" />
                                                    <i className="active icofont-star" />
                                                    <i className="active icofont-star" />
                                                    <i className="active icofont-star" />
                                                    <i className="icofont-star" />
                                                    <NavLink to="#">(3 reviews)</NavLink>
                                                </div>
                                                <h3 className="details-price">
                                                    {data?.product_mrp && (
                                                        <>
                                                            <del
                                                                style={{
                                                                    fontSize:
                                                                        "22px",
                                                                }}
                                                            >
                                                                ₹{" "}
                                                                {
                                                                    data?.product_mrp
                                                                }
                                                                {/* <small></small> */}
                                                            </del>{" "}
                                                            &nbsp;
                                                        </>
                                                    )}
                                                    <span
                                                        style={{
                                                            color: "#0e76a8",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        ₹ {data?.product_price}
                                                        <small></small>
                                                    </span>
                                                </h3>
                                                <p className="details-desc">
                                                    <ul
                                                        style={{
                                                            listStyle: "disc",
                                                        }}
                                                    >
                                                        {parse(
                                                            data?.short_description.slice(
                                                                0,
                                                                2000
                                                            )
                                                        )}

                                                        <h4
                                                            style={{
                                                                color: "#0e76a8",
                                                            }}
                                                        >
                                                            {/* Key Features: */}
                                                        </h4>

                                                        {/* <li className="bullet">
                                                            {parse(
                                                                data?.key_features
                                                            )}
                                                        </li> */}

                                                        <ul>
                                                            {data?.key_features
                                                                ?.replace(
                                                                    /<[^>]+>/g,
                                                                    ""
                                                                )
                                                                .replace(
                                                                    /&nbsp;/g,
                                                                    " "
                                                                )
                                                                .trim()
                                                                .split("\n")
                                                                .filter(
                                                                    (feature) =>
                                                                        feature.trim() !==
                                                                        ""
                                                                )
                                                                .map(
                                                                    (
                                                                        feature,
                                                                        index
                                                                    ) => (
                                                                        <li
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="bullet"
                                                                        >
                                                                            {feature.trim()}
                                                                        </li>
                                                                    )
                                                                )}
                                                        </ul>

                                                        {/* <li>Mu                          ltiple purification processes: RO, UV, UF, and TDS control</li>
                                                        <li>Detachable storage tank: 8 liters capacity for cleaning</li>
                                                        <li>Mineral RO technology: Makes water 100% pure and rich in minerals</li>
                                                        <li>Detachable storage tank: 8 liters capacity for cleaning</li>
                                                        <li>Retains Essential Minerals, , ABS Food Grade Plastic, UV lamp alarm</li> */}
                                                    </ul>
                                                </p>

                                                <div className="details-list-group">
                                                    <label className="details-list-title">
                                                        Share:
                                                    </label>
                                                    <ul className="details-share-list">
                                                        <li>
                                                            <NavLink
                                                                to="#"
                                                                className="icofont-facebook"
                                                                title="Facebook"
                                                            />
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                to="#"
                                                                className="icofont-twitter"
                                                                title="Twitter"
                                                            />
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                to="#"
                                                                className="icofont-linkedin"
                                                                title="Linkedin"
                                                            />
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                to="#"
                                                                className="icofont-instagram"
                                                                title="Instagram"
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>

                                                <ProductAddButton data={data} />
                                                {/* <PopupForm/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section
                                className="inner-section"
                                style={{
                                    background: "rgba(128, 128, 128, 0.09)",
                                }}
                            >
                                <div className="container desTop">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <ul className="nav nav-tabs">
                                                <li>
                                                    <NavLink
                                                        to="#tab-desc"
                                                        className="tab-link active"
                                                        data-toggle="tab"
                                                    >
                                                        descriptions
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        to="#tab-spec"
                                                        className="tab-link"
                                                        data-toggle="tab"
                                                    >
                                                        Specifications
                                                    </NavLink>
                                                </li>
                                                {/* <li>
                                                    <NavLink
                                                        to="#tab-reve"
                                                        className="tab-link"
                                                        data-toggle="tab"
                                                    >
                                                        reviews (2)
                                                    </NavLink>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade show active"
                                        id="tab-desc"
                                    >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="product-details-frame">
                                                    <div className="tab-descrip">
                                                        {parse(
                                                            data?.product_description
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="tab-spec"
                                    >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="product-details-frame">
                                                    {/* {parse(data?.key_features)} */}
                                                    {data &&
                                                        data.tech_specification && (
                                                            <div>
                                                                {parse(
                                                                    data.tech_specification
                                                                )}
                                                            </div>
                                                        )}
                                                    {/* <table className="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">Product </th>
                                                                <td>KENT Pristine UV</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Product Code</th>
                                                                <td>11124</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Applications</th>
                                                                <td>Ideal for Tap/Municipal Water Supply</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Purification Production Rate</th>
                                                                <td>60 L/hr. (depending on inlet water pressure)</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Body Material</th>
                                                                <td>ABS Food Grade Plastic</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Mounting</th>
                                                                <td>Wall mounting/Counter-top</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Storage Capacity</th>
                                                                <td>8 L</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Total Power Consumption</th>
                                                                <td>36 W</td>
                                                            </tr>
                                                        </tbody>
                                                    </table> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="tab-reve"
                                    >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="product-details-frame">
                                                    <ul className="review-list">
                                                        <li className="review-item">
                                                            <div className="review-media">
                                                                <NavLink
                                                                    className="review-avatar text-center"
                                                                    to="#"
                                                                >
                                                                    <img loading="lazy"
                                                                        src="https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector-PNG-File.png"
                                                                        className="logo"
                                                                    />
                                                                </NavLink>
                                                                <h5 className="review-meta">
                                                                    <NavLink to="#">
                                                                        Navneet
                                                                    </NavLink>
                                                                    <span>
                                                                        June 02,
                                                                        2020
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                            <ul className="review-rating">
                                                                ~
                                                                <li className="icofont-ui-rating" />
                                                                <li className="icofont-ui-rating" />
                                                                <li className="icofont-ui-rating" />
                                                                <li className="icofont-ui-rating" />
                                                                <li className="icofont-ui-rate-blank" />
                                                            </ul>
                                                            <p className="review-desc">
                                                                Lorem ipsum
                                                                dolor sit amet
                                                                consectetur
                                                                adipisicing
                                                                elit. Ducimus
                                                                hic amet qui
                                                                velit, molestiae
                                                                suscipit
                                                                perferendis,
                                                                autem doloremque
                                                                blanditiis
                                                                dolores nulla
                                                                excepturi ea
                                                                nobis!
                                                            </p>
                                                            <form className="review-reply">
                                                                <input
                                                                    type="text"
                                                                    placeholder="reply your thoughts"
                                                                />
                                                                <button>
                                                                    <i className="icofont-reply" />
                                                                    reply
                                                                </button>
                                                            </form>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <Footer />
                            {/* {cartShow && <CartItem />} */}
                        </>
                    );
                }
            })}
        </>
    );
}

export default ServiceVew;
