import axios from "axios";
import React, { useEffect, useState } from "react";
import BassURl from "../Api/Api";

// Make sure to have a default image in your project

function DashboardProfile() {
  const [profileImage, setProfileImage] = useState();
  

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfileImage(reader.result);
    };
    reader.readAsDataURL(file);
  };
  return (
    <div className="dashboard-profile-container">
      <form className="dashboard-profile-form">
        <div className="dashboard-profile-image-upload">
          <div className="dashboard-profile-image-preview">
            <img loading="lazy"
              src={profileImage ? profileImage : "/assets/img/profile.png"}
              alt="Profile"
              className="dashboard-profile-image"
            />
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            id="profileImageInput"
            className="dashboard-profile-input-file"
          />
          <label
            htmlFor="profileImageInput"
            className="dashboard-profile-image-label"
          >
            <i className="camera-icon">📷</i>
          </label>
        </div>
        <div className="dashboard-profile-field">
          <label htmlFor="fullName">Full Name*</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            defaultValue="Satya Pal"
            className="dashboard-profile-input"
          />
        </div>
        <div className="dashboard-profile-field">
          <label htmlFor="email">Email*</label>
          <input
            type="email"
            id="email"
            name="email"
            defaultValue="satyalaravel2023@"
            className="dashboard-profile-input"
          />
        </div>
        <div className="dashboard-profile-field">
          <label htmlFor="phone">Phone*</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            defaultValue="9877924929"
            className="dashboard-profile-input"
          />
        </div>
        <button type="submit" className="dashboard-profile-button">
          Update Profile
        </button>
      </form>
    </div>
  );
}

export default DashboardProfile;
