import React from 'react'
import { NavLink } from 'react-router-dom';

export const Thankyou = () => {
  return (
    <>
    <div className="content">
        <div className="wrapper-1">
            <div className="wrapper-2">
                <h1>Thank you !</h1>
                <p>Your Submission is received and we will contact you soon</p>
                <NavLink to="/" className="go-home">go home</NavLink>
            </div>
        </div>
    </div>
    
    </>

  );
}
