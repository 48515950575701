import React from "react";

import Navbar from "../Navbar/Navbar";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { app } from "../firebase.config";
import jwt_decode from "jwt-decode";
import { gapi } from "gapi-script";
// import { useStateValue } from "../Context/StateProvider";
// import { actionType } from "../Context/reducer";
import { async } from "@firebase/util";
import axios from "axios";
import BassURl from "../Api/Api";
import { googleLoginUser, loginUser } from "../Redux/Action/UserAction";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";

function Login() {
  const dispatch = useDispatch();
  const [isMenu, setIsMenu] = useState(false);

  // Google login function

  const firebaseAuth = getAuth(app);
  const provider = new GoogleAuthProvider();

  const login = async () => {
    await signInWithPopup(firebaseAuth, provider)
      .then((result) => {
        console.log(result.user);
        // alert(result.user);
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        localStorage.setItem("googleUser", JSON.stringify(user));

        console.log(token, user, "google");
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorMessage);
        // ...
      });
    // dispatch(googleLoginUser(formValues));
    window.location.href = "/";
  };

  // facebook login function

  const facebookprovider = new FacebookAuthProvider();
  const facebook = async () => {
    await signInWithPopup(firebaseAuth, facebookprovider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        console.log(user)
        alert(user)
        localStorage.setItem("fbuser", JSON.stringify(user));
        window.location.href = "/";

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
          console.log(error )
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);

        // ...
      });

  };

  const logout = () => {
    localStorage.clear();

    // dispatch({
    //   type: actionType.SET_USER,
    //   user: null,
    // });
  };

  // From login function

  let initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  };
  let handleSubmit = (e) => {
    axios
      .post(`${BassURl}/api/login`, formValues)
      .then((res) => {
        dispatch(loginUser(formValues));
        // window.location.href = "/";
      })
      // catch error if their is any error
      .catch((err) => {
        console.log(err);
      });

    e.preventDefault();
  };
  const userss = {
    name: "shubham",
    age: "12",
  };
  // Decrypt

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <section className="log-in-section background-image-2 section-b-space mt-5">
        <div className="container-fluid-lg w-100">
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 d-lg-block d-none ms-auto">
              <div className="image-contain">
                <img loading="lazy"
                  src="/assets/img/log-in.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-8 me-auto">
              <div className="log-in-box">
                <div className="log-in-title">
                  <h3>Welcome To Aquamart</h3>
                  <h4>Log In Your Account</h4>
                </div>
                <div className="input-box">
                  <form>
                    <div className="form">
                      <div className="form-item">
                        <input
                          type="email"
                          id="username"
                          autoComplete="off"
                          value={formValues.email}
                          onChange={handleChange}
                          name="email"
                          required
                        />
                        <label htmlFor="username">Email</label>
                      </div>

                      <div className="form-item">
                        <input
                          type="password"
                          id="password"
                          autoComplete="off"
                          name="password"
                          value={formValues.password}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="password">Password</label>
                      </div>

                      {/* <div className="form-item">
                        <input
                          type="password"
                          name="password"
                          value={formValues.password}
                          onClick={handleChange}
                          id="password"
                          autoComplete="off"
                          required
                        />
                        <label htmlFor="password">Password</label>
                      </div> */}
                    </div>

                    <div className="col-12 text-center">
                      <NavLink
                        className="btn btn-login "
                        style={{ color: "white" }}
                        onClick={handleSubmit}
                      >
                        Log In
                      </NavLink>
                    </div>
                  </form>
                </div>
                <div className="other-log-in">
                  <h6>or</h6>
                </div>
                <div className="log-in-button">
                  <ul>
                    <li style={{ padding: "10px" }}>
                      <a
                        className="btn google-button w-100"
                        onClick={() => {
                          login();
                        }}
                      >
                        <img loading="lazy"
                          src="/assets/img/google.png"
                          className="blur-up lazyloaded"
                          alt=""
                        />{" "}
                        <h6>Log In with Google</h6>
                      </a>
                    </li>

                    <li style={{ padding: "10px" }}>
                      <a
                        className="btn google-button w-100"
                        onClick={() => {
                          facebook();
                        }}
                      >
                        <img loading="lazy"
                          src="/assets/img/facebook.png"
                          className="blur-up lazyloaded"
                          alt=""
                        />{" "}
                        <h6>Log In with Facebook</h6>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="other-log-in">
                  <h6 />
                </div>
                <div className="sign-up-box">
                  <h4>Don't have an account?</h4>
                  <NavLink to="/registration">Sign Up</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
