import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import BassURl from "../Api/Api";
import axios from "axios";
import { addNewAdress } from "../Redux/Action/UserAction";
import { IoClose } from "react-icons/io5";
import DashboardProfile from "../Component/DashboardProfile";
import { FaShoppingCart, FaTruck, FaTimesCircle } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid grey",
  boxShadow: 24,
  p: 2,
};

function Dashboard() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [address, setUserData] = useState();
  const [encodeID, setEncodeID] = useState()

useEffect(()=>{
  const user = JSON.parse(window.localStorage.getItem("currentUser"));
  setUserData(user)
  let encode = window.btoa(user.id)
  setEncodeID(encode)
  console.log(typeof(encodeID))
},[])
  
  const prefill = {
    name: address?.first_name + address?.last_name,
    email: address?.email,
    contact: address?.mobile,
  };

  let initialValues = {
    pincode: "",
    address: "",
    city: "",
    state: "",
    country: "null",
    latitude: "null",
    longitude: "null",
    type: "",
    user_id: address?.id,
  };

  const [UserAddress, setUserAddress] = useState(initialValues);
  const [formValues, setFormValues] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserAddress({ ...UserAddress, [name]: value });
    // console.log(UserAddress);
  };
  // console.log(UserAddress);
  function postAddress(e) {
    e.preventDefault();

    dispatch(addNewAdress(UserAddress));
  }

  const [getAdress, setGetAdress] = useState();

  useEffect(() => {
    axios
      .get(`${BassURl}/api/user-address/${address?.id}`)
      .then((res) => {
        // console.log(res.data.data);
        setGetAdress(res.data.data);
      })
      .catch((error) => {
        console.log("somthing went wrong");
      });
  }, []);

  const handelLogout = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  // store Address
  const [activeId, setActiveId] = useState();
  window.localStorage.setItem("address_id", JSON.stringify(activeId));

  const [myOrder, setMyOrder] = useState();

  useEffect(() => {
    // alert("i m in")
    const user = JSON.parse(localStorage.getItem("currentUser"));
    console.log(user?.id);
    axios.get(`${BassURl}/api/my-order/${user?.id}`).then((response) => {
      setMyOrder(response.data.data);
    });
    console.log(myOrder);
  }, []);




  const [passData, setpassData] = useState({
    
      client_id:encodeID,
      old_password:"",
      confirm_password:"",
      new_password:""
  
  })

  function handelUpdatePass(e){
    const { name, value } = e.target;
    setpassData((prev) => ({
      ...prev,
      [name]: value,
      client_id:encodeID
    }));
  }

 async function handleChangePassword(e){
    e.preventDefault();
    try {
      const resp = await axios.post(`${BassURl}/api/update-password`,passData)
      console.log(resp)
      if(resp.data.code == 200){
     toast.success(resp.data.msg)
      }
    } catch (error) {
      toast.error(error.msg)
    }
  }
  return (
    <>
      <div>
        <div className="dashboard-head">
          {/* <img loading="lazy" src="/assets/img/logo-with-tm.png" className="logo" /> */}
          <h2>USER DASHBOARD</h2>
          <NavLink to="/">
            <Button className="dashboard-head-button" variant="outlined">
              Go to Home
            </Button>
          </NavLink>
        </div>
        <div className="dashboard-body">
          <Tabs>
            <div className="row dashboard-content">
              <TabList className="col-3 tab-head">
                <img loading="lazy" src="/assets/img/profile.png" width={150} />
                <h2>User Name</h2>
                <Tab defaultFocus={true} className="tab-head-content">
                  Profile
                </Tab>
                <Tab className="tab-head-content">My Orders</Tab>
                <Tab className="tab-head-content">Address</Tab>
                <Tab className="tab-head-content">Account Setting</Tab>
                <Tab className="tab-head-content">Change Password</Tab>
                <button
                  className=""
                  onClick={() => {
                    handelLogout();
                  }}
                >
                  Logout
                </button>
              </TabList>
              <div className="col-9">
                <TabPanel>
                  <div className="profile-dashboard">
                    <h2>Profile</h2>
                    <div className="profile-container">
                      <h3>Hello, User Name</h3>
                      <h1>Welcome to your Profile</h1>
                      <div className="order-stats">
                        <div className="order-card">
                          <FaShoppingCart size="2em" className="order-icon" />
                          <p>New Orders</p>
                          <h2>25</h2>
                        </div>
                        <div className="order-card">
                          <FaTruck size="2em" className="order-icon" />
                          <p>Delivered Orders</p>
                          <h2>2</h2>
                        </div>
                        <div className="order-card">
                          <FaTimesCircle size="2em" className="order-icon" />
                          <p>Cancel Orders</p>
                          <h2>0</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="order-dashboard">
                    <h2>My Orders</h2>
                    {myOrder?.map((item, index) => {
                      console.log(item)
                      return (
                        <>
                          <div className="dashboard-order-card ">
                            <div className="dashboard-order-header row">
                              <div className="col-3">
                                <p>Order Placed</p>
                                <p>{item?.date_time}</p>
                              </div>
                              <div className="col-2">
                                <p>Total</p>
                                <p>{item?.total_amount}</p>
                              </div>
                              <div className="col-2">
                                <p>Ship To</p>
                                <p>
                                  <NavLink to="#">{`${item?.first_name} ${item?.last_name}`}</NavLink>
                                </p>
                              </div>
                              <div className="col-5 text-end">
                                <p>ORDER: {item?.order_id}</p>
                                <p>
                                    
                                  <NavLink
                                    to={"/invoice"}
                                    className="dashboard-link"
                                  >
                                    {" "}
                                    Invoice
                                  </NavLink>
                                </p>
                              </div>
                            </div>
                            <div className="dashboard-order-body row mt-3">
                              <div className="col-2">
                                <img loading="lazy"
                                  src="https://via.placeholder.com/60"
                                  alt="Product"
                                  className="dashboard-product-image"
                                />
                              </div>
                              <div className="col-10">
                                <p>Classic Round Neck</p>
                                <p>1</p>
                                <p>₹ 1500</p>
                                <p>S-6</p>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    className="d-flex justify-content-center "
                    style={{
                      width: "100%",
                      padding: "30px 50px ",
                    }}
                  >
                    <div>
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                          marginBottom: "40px",
                        }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            borderRadius: "21px",
                            background: "#ffffff",
                            boxShadow:
                              "5px 5px 41px #919191, -5px -5px 41px #ffffff",
                            padding: "10px 25px",
                          }}
                        >
                          <h4
                            className=" mb-0"
                            style={{ fontFamily: "Mulish", fontWeight: "700" }}
                          >
                            Delivery Address
                          </h4>
                          <img loading="lazy"
                            src="/assets/icons/svg/location.gif"
                            loop="infinite"
                            alt=""
                            className=""
                            width={50}
                          />
                        </div>
                        <div className="position-relative">
                          {/* <i className="fa-solid fa-plus d-block d-sm-none m-0" />
                      <span className="d-none d-sm-block">+ Add New</span> */}
                          <Button
                            onClick={handleOpen}
                            style={{
                              background: "#0299dd",
                              border: "none",
                              color: "#fff",
                              padding: "8px 15px",
                              fontSize: "15px",
                              fontWeight: "600",
                            }}
                          >
                            Add Address +
                          </Button>
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Enter Shipping Details
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                              >
                                <div className="">
                                  <div className="offcanvas-body small w-100">
                                    <form
                                      className="custom-form w-100"
                                      onSubmit={postAddress}
                                    >
                                      <div className="input-box mb-3">
                                        <input
                                          type="text"
                                          name="pincode"
                                          value={UserAddress.pincode}
                                          onChange={handleChange}
                                          placeholder="postal code"
                                          id="form3Example3"
                                          className="form-control"
                                        />
                                      </div>
                                      <div className="input-box">
                                        <input
                                          type="text"
                                          name="state"
                                          value={UserAddress.state}
                                          onChange={handleChange}
                                          placeholder="State"
                                          className="form-control"
                                          style={{ marginTop: "17px" }}
                                        />
                                      </div>
                                      <div className="input-box">
                                        <input
                                          type="text"
                                          name="city"
                                          value={UserAddress.city}
                                          onChange={handleChange}
                                          placeholder="City"
                                          className="form-control"
                                        />
                                      </div>
                                      <div className="input-box">
                                        <input
                                          type="text"
                                          name="address"
                                          placeholder="Address"
                                          value={UserAddress.address}
                                          onChange={handleChange}
                                          className="form-control"
                                          style={{ marginTop: "17px" }}
                                        />
                                      </div>
                                      <div className="input-box">
                                        <input
                                          type="text"
                                          name="type"
                                          placeholder="Address Type Link: home office other"
                                          value={UserAddress.type}
                                          onChange={handleChange}
                                          className="form-control"
                                          style={{ marginTop: "17px" }}
                                        />
                                      </div>
                                    </form>
                                  </div>
                                  <div className="offcanvas-footer">
                                    <div className="btn-box">
                                      <button
                                        className="btn-solid font-md"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                        type="submit"
                                        onClick={postAddress}
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className=""
                                  style={{
                                    top: "20px",
                                    right: "35px",
                                    position: "absolute",
                                    cursor: "pointer",
                                  }}
                                >
                                  <IoClose
                                    onClick={handleClose}
                                    style={{
                                      fontSize: "28px",
                                      fontWeight: "600",
                                    }}
                                  />
                                </div>
                              </Typography>
                            </Box>
                          </Modal>
                        </div>
                      </div>
                      <div className="row">
                        {getAdress &&
                          getAdress?.map((data) => {
                            return (
                              <div className="col-xxl-6 col-lg-6 col-md-6 pb-4">
                                <div
                                  className="delivery-address-box  "
                                  onClick={() => setActiveId(data.id)}
                                  style={{
                                    borderRadius: "5px",
                                    background: "#ffffff",
                                    boxShadow:
                                      "5px 5px 41px #919191, -5px -5px 41px #ffffff",
                                    padding: "0",
                                  }}
                                >
                                  <div className="d-flex align-items-center justify-content-between px-2 address-head-checkout">
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        gap: "10px",
                                      }}
                                    >
                                      <div className="">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="jack"
                                          id="flexRadioDefault1"
                                        />
                                      </div>
                                      <div>
                                        <h4
                                          className="m-0"
                                          style={{
                                            fontWeight: "600",
                                            fontSize: "1.29 rem",
                                          }}
                                        >
                                          {address?.first_name +
                                            " " +
                                            address?.last_name}
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="address-label">
                                      <label>{data?.type}</label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      padding: "15px 20px",
                                    }}
                                  >
                                    <ul className="delivery-address-detail">
                                      <li>
                                        <p className="text-content">
                                          <span
                                            className="text-title "
                                            style={{
                                              fontWeight: "700",
                                            }}
                                          >
                                            Address :{" "}
                                          </span>
                                          {data?.address}
                                        </p>
                                      </li>
                                      <li>
                                        <h6 className="text-content">
                                          <span
                                            className="text-title"
                                            style={{
                                              fontWeight: "700",
                                            }}
                                          >
                                            Pin Code :
                                          </span>{" "}
                                          {data?.pincode}
                                        </h6>
                                      </li>
                                      <li>
                                        <h6 className="text-content mb-0">
                                          <span
                                            className="text-title"
                                            style={{
                                              fontWeight: "700",
                                            }}
                                          >
                                            Phone :
                                          </span>{" "}
                                          {address?.mobile}
                                        </h6>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="dashboard-profile-body">
                    <h2>Account Setting</h2>
                    <DashboardProfile />
                  </div>
                </TabPanel>
                <TabPanel>
                  <section>
                    <div className="dashboard-container">
                      <div className="dashboard-form-container">
                        <div className="dashboard-header">
                          <h2>Change Password</h2>
                        </div>
                        <form onSubmit={handleChangePassword}>
                          <input
                            value="https://jamstacker.studio/thankyou"
                            type="hidden"
                            name="_redirect"
                          />
                          <div className="dashboard-form-fields">
                            <div className="dashboard-input-group">
                              <label>Current Password</label>
                              <input type="password" onChange={handelUpdatePass} name="old_password" placeholder="******" />
                            </div>
                            <div className="dashboard-input-group">
                              <label>New Password</label>
                              <input type="password" onChange={handelUpdatePass} name="new_password" placeholder="******" />
                            </div>
                            <div className="dashboard-input-group">
                              <label>Confirm New Password</label>
                              <input type="password" onChange={handelUpdatePass} name="confirm_password" placeholder="******" />
                            </div>
                            <div className="dashboard-submit-button">
                              <button type="submit">Submit your request</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                </TabPanel>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
