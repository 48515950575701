import React from 'react'
import { NavLink } from 'react-router-dom'

function Footer() {
    return (
        <>
            {/* ============================ Footer Start ================================== */}
            <footer className="dark-footer skin-dark-footer style-2">
            
                {/*Header ends*/}
                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-5">
                                <div className="footer_widget">
                                    <img loading="lazy"
                                        src="assets/img/logo.webp"
                                        className="img-footer small mb-2"
                                        alt=""
                                    />
                                    <p className='text-light'>
                                    Water purifiers are essential appliances that ensure that our drinking water is clean and safe. However, even the best water purifiers can break down over time. That's where AQUAMART comes in. AQUAMART is one of the Trusted & Independent Water Purifier Service Provider. We offer Ro Installation, Repair & comprehensive water purifier repair services for all Major brands and models.
                                    </p>
                                    <div className="foot-news-last">
                                        {/* <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Email Address"
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="input-group-text theme-bg b-0 text-light"
                                                >
                                                    Subscribe
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 ml-auto">
                                <div className="row">
                                    <div class="col-md-1"></div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="footer_widget">
                                            <h4 className="widget_title">Quick Link</h4>
                                            <ul className="footer-menu">
                                                <li >
                                                    <NavLink  to="/">Home</NavLink>
                                                </li>
                                                <li style={{
                                                    color:"white !important"
                                                }}>
                                                    <NavLink to="/about">About Us</NavLink>
                                                </li>
                                                <li style={{
                                                    color:"white !important"
                                                }}>
                                                    <NavLink to="/contact-us">Contact Us</NavLink>
                                                </li>
                                                <li style={{
                                                    color:"white !important"
                                                }}>
                                                    <NavLink to="/contact-us">Privacy Policy</NavLink>
                                                </li>
                                                <li style={{
                                                    color:"white !important"
                                                }}>
                                                    <NavLink to="/contact-us">Terms&Condition</NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3">
                                        <div className="footer_widget">
                                            <h4 className="widget_title">Appliances</h4>
                                            <ul className="footer-menu">
                                                <li style={{
                                                    color:"white !important"
                                                }}>
                                                    <NavLink to="#">Water Purifiers</NavLink>
                                                </li>
                                                <li style={{
                                                    color:"white !important"
                                                }}>
                                                    <NavLink to="#">Water Softeners</NavLink>
                                                </li>
                                                <li style={{
                                                    color:"white !important"
                                                }}>
                                                    <NavLink to="#">Kitchen</NavLink>
                                                </li>
                                                <li style={{
                                                    color:"white !important"
                                                }}>
                                                    <NavLink to="#">
                                                        Air Purifiers
                                                    </NavLink>
                                                </li>
                                                <li style={{
                                                    color:"white !important"
                                                }}>
                                                    <NavLink to="#">Vacuum Cleaners</NavLink>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-md-5">
                                        <div className="footer_widget">
                                            <h4 className="widget_title">Contact Us</h4>
                                            <ul className="footer-menu">
                                                <li style={{
                                                    color:"white !important"
                                                }}><i class="fas fa-map-marker-alt" style={{color: "#fff"}}></i>  
                                                &nbsp; &nbsp;
                                                    <NavLink to="#">Near Pantaloon outlet,  engineering college chouraha, Aliganj, lucknow</NavLink>
                                                </li>
                                                <li style={{
                                                    color:"white !important"
                                                }}><i class="fas fa-phone" style={{ color: "#fff" }}></i> &nbsp;
                                                    <NavLink to="tel:+919696361300">+91 9696361300</NavLink>
                                                </li>
                                                <li style={{
                                                    color:"white !important"
                                                }}><i class="far fa-envelope-open"  style={{ color: "#fff" }}></i>  
                                                &nbsp; &nbsp;
                                                    <NavLink to="mailto:Care@aquamart.co.in">Care@aquamart.co.in</NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-md-12 text-center">
                                <p className="mb-0">
                                    © 2022  Designd By     {" "}
                                    <NavLink to="https://www.digitalnawab.com/"><i className='fa fa-heart'></i> </NavLink>
                                    <span style={{ color: "#fff" }}>Digital Nawab</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* ============================ Footer End ================================== */}
        </>
    )
}

export default Footer