import React, { useState } from 'react'
import MyContext from './MyContext'

function MyState(props) {
    const [isServiceCartAdded, setIsServiceCartAdded] = useState(false)
  return (
    <MyContext.Provider value={{isServiceCartAdded , setIsServiceCartAdded }}>
       {props.children}
    </MyContext.Provider>
  )
}

export default MyState