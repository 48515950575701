import React from 'react';

const Invoice = () => {
  return (
    <div className="invoice-container">
      <div className="header">
        <h1>Aqua Mart</h1>
      </div>
      <div className="invoice-details">
        <div className="company-details">
          <h2>Design Resource India Pvt Ltd</h2>
          <p>Tapasya Corp heights, Sales office at Ground floor, Sector 126, Noida, Uttar Pradesh 201303.</p>
          <p>Phone no.: +918287589790</p>
          <p>Email: drscrubscustomercare@gmail.com</p>
          <p>GSTIN: 09AAECD3279P1ZZ</p>
        </div>
        <div className="invoice-info">
          <p>Invoice No.: DRS-23-24-1</p>
          <p>Date: 2024-02-19 00:00:00</p>
          <p>Email: satyalaravel2023@gmail.com</p>
          <p>Razorpay Payment ID: pay_Nci8NKSZR9Kz1n</p>
        </div>
      </div>
      <div className="billing-details">
        <p><strong>Satya Pal</strong></p>
        <p>Contact No.: 9877924929</p>
      </div>
      <table className="invoice-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Product name</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td><img loading="lazy" src="https://via.placeholder.com/50" alt="Classic Round Neck" /></td>
            <td>Classic Round Neck</td>
            <td>1</td>
            <td>₹ 1500</td>
            <td>₹ 1500</td>
          </tr>
        </tbody>
      </table>
      <div className="total-amount">
        <p><strong>Invoice Amount In Words</strong></p>
        <p>₹ 1500</p>
      </div>
    </div>
  );
};

export default Invoice;