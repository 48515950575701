import React from "react";
import NavbarService from "../Navbar/NavbarService";
import Footer from "../Component/Footer";
import BassURl from "../Api/Api";
import Navbar from "../Navbar/Navbar";
import "./checkout.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import "../Mobile/Address.css";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { addNewAdress } from "../Redux/Action/UserAction";
import { placeOrder, ServiceplaceOrder } from "../Redux/Action/OrderAction";
import { ToastContainer, toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  boxShadow: 24,
  p: 2,
};

function CheckOutProject() {
  const dispatch = useDispatch();
  const addtocartreducer = useSelector((state) => state?.addToCartReducer);
  const { cartItems } = addtocartreducer;
  console.log(cartItems)
  var subtotal = cartItems?.reduce(
    (acc, item) => acc + item?.product_price * item?.qty,
    0
  );
  // console.log(subtotal)

  const addToServiceCartReducer = useSelector(
    (state) => state?.addToServiceCartReducer
  );
  const { cartItemService } = addToServiceCartReducer;
  var Servicesubtotal = cartItemService?.reduce(
    (acc, item) => acc + item?.service_price * item?.qty,
    0
  );
  const [getAdress, setGetAdress] = useState();

  const address = JSON.parse(window.localStorage.getItem("currentUser"));

  const [addressUser, setAddressUser] = useState()
  console.log(address)
  const prefill = {
    name: address.first_name + address.last_name,
    email: address?.email,
    contact: address?.mobile,
  }



  let initialValues = {
    pincode: "",
    address: "",
    city: "",
    state: "",
    country: "null",
    latitude: "null",
    longitude: "null",
    type: "",
    user_id: address?.id,
  };
  useEffect(() => {
    axios
      .get(`${BassURl}/api/user-address/${address.id}`)
      .then((res) => {
        console.log(res.data.data);
        setGetAdress(res.data.data);
      })
      .catch((error) => {
        console.log("somthing went wrong");
      });
  }, []);

  // store Address
  const [activeId, setActiveId] = useState();
  window.localStorage.setItem("address_id", JSON.stringify(activeId));
  // add mew address
  const [showResults, setShowResults] = React.useState(false);
  function onShow() {
    setShowResults(true);
  }
  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: "100%" },
  };
  const [UserAddress, setUserAddress] = useState(initialValues);
  const [formValues, setFormValues] = useState(initialValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserAddress({ ...UserAddress, [name]: value });
    console.log(UserAddress);
  };
  console.log(UserAddress);
  function postAddress(e) {
    e.preventDefault();

    dispatch(addNewAdress(UserAddress));
  }
  const [PaymentId, setPaymentId] = useState("");
  const [radioValue, setRadioValue] = useState(false);
  const [UPI, setUPI] = useState(false)
  const onCash = (ev) => {
    //save your value here with state variable
    console.log(ev.target.value);
    setRadioValue(ev.target.value);
  }
  const onUpi = (ev) => {
    //save your value here with state variable
    console.log(ev.target.value)
    setUPI(ev.target.value)
  }

  const cartItemss = JSON.parse(window.localStorage.getItem("cartItems"));
  console.log(cartItemss)
  const cartItemservice = JSON.parse(
    window.localStorage.getItem("cartServiceItem")
  );
  console.log(cartItemService)

  const navigate = useNavigate()

  function serviceCOD() {
    // console.log("inside service COD")
    // alert("inside COD")
    dispatch(ServiceplaceOrder(Servicesubtotal, "COD"));
    // window.location.href = '/order-success'
    // navigate('/order-success')
  }

  function productCOD() {
    console.log("productCod")
    const orderInfo = {
      type:'product',
      cartItems,
      date: new Date().toLocaleString(
        "en-US",
        {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }
      )
    }
    dispatch(placeOrder(subtotal, "COD" ,"product", orderInfo));
  }

  // get alert while radio select
  function tokenHandler() {

    var options = {
      key: "rzp_test_5PAT2oLWzW8TmQ",
      // key_secret: "R5j77v4uZIK6N6zwNVyCgrhP",
      amount: 400 * 100,
      currency: "INR",
      name: "Digital Nawab",
      description: "for testing purpose",
      handler: function (response) {
        // console.log(response)
        toast.success('Payment Successful')
        const paymentId = response.razorpay_payment_id
        // window.location.href = "/order-success";
        const orderInfo = {
          cartItems,
          date: new Date().toLocaleString(
            "en-US",
            {
              month: "short",
              day: "2-digit",
              year: "numeric",
            }
          ),
          paymentId
        }
        dispatch(ServiceplaceOrder(Servicesubtotal, "Online", orderInfo));
        try {
          console.log(orderInfo, "order Info")
        } catch (error) {
          console.log(error)
        }
      },

      theme: {
        color: "#3399cc"
      }


    };
    var pay = new window.Razorpay(options);
    pay.open();
    console.log(pay)
  }



  function productSend() {
    console.log("productOnline")

    var options = {
      key: "rzp_test_5PAT2oLWzW8TmQ",
      // key_secret: "R5j77v4uZIK6N6zwNVyCgrhP",
      amount: 400 * 100,
      currency: "INR",
      name: "Digital Nawab",
      description: "for testing purpose",
      handler: function (response) {
        // console.log(response)
        toast.success('Payment Successful')
        const paymentId = response.razorpay_payment_id
        
        const orderInfo = {
          cartItems,
          date: new Date().toLocaleString(
            "en-US",
            {
              month: "short",
              day: "2-digit",
              year: "numeric",
            }
          ),
          paymentId
        }
        dispatch(placeOrder(subtotal, "Online", orderInfo));
        try {
          console.log(orderInfo, "order Info")
        } catch (error) {
          console.log(error)
        }
      },

      theme: {
        color: "#3399cc"
      }


    };
    var pay = new window.Razorpay(options);
    pay.open();
    console.log(pay)
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <NavbarService />
      <div
        className="page-title"
        style={{
          background:
            "#f4f4f4 url(https://www.kent.co.in/images/banner/banner-bg.jpg)",
        }}
        data-overlay={5}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="breadcrumbs-wrap">
                <h2 className="breadcrumb-title">Checkout - Checkout</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="checkout-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row g-sm-8  g-8">
            <div className="col-lg-7 " style={{
              paddingRight: "30px"
            }}>
              <div className="d-flex align-items-center justify-content-between" style={{
                marginBottom: "40px"
              }}>
                <div className="d-flex align-items-center" style={{
                  borderRadius: "21px",
                  background: "#ffffff",
                  boxShadow: "5px 5px 41px #919191, -5px -5px 41px #ffffff",
                  padding: "10px 25px"
                }}>
                  <h4
                    className=" mb-0"
                    style={{ fontFamily: "Mulish", fontWeight: "700" }}
                  >
                    Delivery Address
                  </h4>
                  <img loading="lazy"
                    src="/assets/icons/svg/location.gif"
                    loop="infinite"
                    alt=""
                    className=""
                    width={50}
                  />
                </div>
                <div className='position-relative'>
                  {/* <i className="fa-solid fa-plus d-block d-sm-none m-0" />
                      <span className="d-none d-sm-block">+ Add New</span> */}
                  <Button onClick={handleOpen} style={{
                    background: "#0299dd",
                    border: "none",
                    color: "#fff",
                    padding: "8px 15px",
                    fontSize: "15px",
                    fontWeight: "600"
                  }}>Add Address +</Button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Enter Shipping Details
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div className="">
                          <div className="offcanvas-body small w-100">
                            <form
                              className="custom-form w-100"
                              onSubmit={postAddress}
                            >
                              <div className="input-box mb-3">
                                <input
                                  type="text"
                                  name="pincode"
                                  value={UserAddress.pincode}
                                  onChange={handleChange}
                                  placeholder="postal code"
                                  id="form3Example3"
                                  className="form-control"
                                />
                              </div>
                              <div className="input-box">
                                <input
                                  type="text"
                                  name="state"
                                  value={UserAddress.state}
                                  onChange={handleChange}
                                  placeholder="State"
                                  className="form-control"
                                  style={{ marginTop: "17px" }}
                                />
                              </div>
                              <div className="input-box">
                                <input
                                  type="text"
                                  name="city"
                                  value={UserAddress.city}
                                  onChange={handleChange}
                                  placeholder="City"
                                  className="form-control"
                                />
                              </div>
                              <div className="input-box">
                                <input
                                  type="text"
                                  name="address"
                                  placeholder="Address"
                                  value={UserAddress.address}
                                  onChange={handleChange}
                                  className="form-control"
                                  style={{ marginTop: "17px" }}
                                />
                              </div>
                              <div className="input-box">
                                <input
                                  type="text"
                                  name="type"
                                  placeholder="Address Type Link: home office other"
                                  value={UserAddress.type}
                                  onChange={handleChange}
                                  className="form-control"
                                  style={{ marginTop: "17px" }}
                                />
                              </div>
                            </form>
                          </div>
                          <div className="offcanvas-footer">
                            <div className="btn-box">
                              <button
                                className="btn-solid font-md"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                type="submit"
                                onClick={postAddress}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='' style={{
                          top: "20px",
                          right: "35px",
                          position: "absolute",
                          cursor: "pointer"
                        }}>
                          <IoClose onClick={handleClose} style={{
                            fontSize: "28px",
                            fontWeight: "600"
                          }} />
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                </div>
              </div>
              <div className="row">
                {getAdress &&
                  getAdress?.map((data) => {
                    return (
                      <div className="col-xxl-6 col-lg-6 col-md-6 pb-4" >
                        <div
                          className="delivery-address-box  "
                          onClick={() => setActiveId(data.id)}
                          style={{
                            borderRadius: "5px",
                            background: "#ffffff",
                            boxShadow: "5px 5px 41px #919191, -5px -5px 41px #ffffff",
                            padding: "0",
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between px-2 address-head-checkout">
                            <div className="d-flex align-items-center" style={{
                              gap: "10px"
                            }}>
                              <div className="">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="jack"
                                  id="flexRadioDefault1"
                                />
                              </div>
                              <div>
                                <h4 className="m-0" style={{
                                  fontWeight: "600",
                                  fontSize: "1.29 rem"
                                }}>
                                  {address?.first_name +
                                    " " +
                                    address?.last_name}
                                </h4>
                              </div>
                            </div>
                            <div className="address-label">
                              <label>{data?.type}</label>
                            </div>
                          </div>
                          <div style={{
                            padding: "15px 20px"
                          }}>
                            <ul className="delivery-address-detail">
                              <li>
                                <p className="text-content">
                                  <span className="text-title " style={{
                                    fontWeight: "700"
                                  }}>
                                    Address :{" "}
                                  </span>
                                  {data?.address}
                                </p>
                              </li>
                              <li>
                                <h6 className="text-content">
                                  <span className="text-title" style={{
                                    fontWeight: "700"
                                  }}>
                                    Pin Code :
                                  </span>{" "}
                                  {data?.pincode}
                                </h6>
                              </li>
                              <li>
                                <h6 className="text-content mb-0">
                                  <span className="text-title" style={{
                                    fontWeight: "700"
                                  }}>
                                    Phone :
                                  </span>{" "}
                                  {address?.mobile}
                                </h6>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-lg-5" style={{
              paddingLeft: "30px"
            }}>
              <div className="d-flex align-items-center " style={{
                width: "282px",
                borderRadius: "21px",
                background: "#ffffff",
                boxShadow: "5px 5px 41px #919191, -5px -5px 41px #ffffff",
                padding: "10px 25px",
                marginBottom: "40px"
              }}>
                <h4
                  className="tab-title mb-0"
                  style={{ fontFamily: "Mulish", fontWeight: "700" }}
                >
                  {" "}
                  Payment Option
                </h4>
                <img loading="lazy"
                  src="/assets/icons/svg/coins.gif"
                  loop="infinite"
                  alt=""
                  className=""
                  width={50}
                />
              </div>
              <div className="checkout-area">
                <div className="order-area">
                  <div className="check-pro">
                    <h2 style={{ fontFamily: "Rubik, sans-serif" }}>
                      In Your Cart{" "}
                    </h2>
                    <ul className="check-ul">

                      {cartItemss &&
                        cartItemss?.map((data, index) => {
                          return (
                            <>
                              <li key={index}>
                                <div className="check-pro-img">
                                  <a>
                                    <img loading="lazy"
                                      style={{
                                        height: "100px",
                                        border: "1px solid grey",
                                        borderRadius: "10px",
                                        padding: "6px"
                                      }}
                                      src={
                                        BassURl +
                                        data?.product_image
                                      }
                                      className="img-fluid"
                                      alt="image"
                                    />
                                  </a>
                                </div>
                                <div className="check-content">
                                  <a>{data?.product_title}</a>
                                  <span className="check-code-blod">
                                    Product Qty:{" "}
                                    <span>{data?.qty}</span>
                                  </span>
                                  <span className="check-price">
                                    Price: ₹{" "}
                                    {data?.product_price *
                                      data?.qty}
                                  </span>
                                </div>
                              </li>
                            </>
                          );
                        })}


                      {cartItemservice &&
                        cartItemservice?.map((data, index) => {
                          console.log(data, "cart service data")
                          return (
                            <>
                              <li key={index}>
                                <div className="check-pro-img"  >
                                  <a>
                                    <img loading="lazy"
                                      style={{
                                        height: "100px",
                                        border: "1px solid grey",
                                        borderRadius: "10px",
                                        padding: "6px"
                                      }}
                                      src={
                                        BassURl + data?.service_image
                                      }
                                      className="img-fluid"
                                      alt="image"
                                    />
                                  </a>
                                </div>
                                <div className="check-content">
                                  <a>{data?.service_title}</a>
                                  <span className="check-code-blod">
                                    Product Qty:{" "}
                                    <span>{data?.qty}</span>
                                  </span>
                                  <span className="check-price">
                                    Price: ₹{" "}
                                    {data?.service_price * data?.qty}
                                  </span>
                                </div>
                              </li>
                            </>
                          );
                        })}
                    </ul>
                  </div>
                  <h2
                  >
                    Your order{" "}
                  </h2>
                  <ul className="order-history">
                    <li className="order-details">
                      <span
                        style={{ fontFamily: " 'Rubik', sans-serif" }}
                      >
                        service:
                      </span>
                      <span
                        style={{ fontFamily: " 'Rubik', sans-serif" }}
                      >
                        Total
                      </span>
                    </li>

                    <li className="order-details">
                      <span
                        style={{ fontFamily: " 'Rubik', sans-serif" }}
                      >
                        Shipping Charge:
                      </span>
                      <span>Free shipping</span>
                    </li>
                    <li className="order-details">
                      <span
                        style={{ fontFamily: " 'Rubik', sans-serif" }}
                      >
                        Total:
                      </span>
                      {cartItemss && (
                        <>
                          {" "}
                          <span
                            style={{
                              fontFamily: " 'Rubik', sans-serif",
                            }}
                          >
                            ₹ {subtotal}
                          </span>
                        </>
                      )}
                      {
                        cartItemservice && (
                          <span
                            style={{ fontFamily: " 'Rubik', sans-serif" }}
                          >
                            ₹ {Servicesubtotal}
                          </span>
                        )
                      }
                    </li>
                  </ul>
                  <div className="container-service-payment">
                    <input type="radio" id="button1" name="upi"
                      onChange={onUpi}
                      value="online" className="custom-radio-payment" />
                    <label htmlFor="button1" className="custom-button-payment">Pay with UPI</label>
                    <input type="radio" defaultChecked id="button2" name="upi"
                      onChange={onCash}
                      value="cod" className="custom-radio-payment" />
                    <label htmlFor="button2" className="custom-button-payment">Cash on Delivery</label>
                  </div>
                  {cartItemss ? (
                    <>
                      {" "}
                      <div className="checkout-btn">
                        <NavLink
                          className="btn-style1"
                          onClick={radioValue ? productCOD : productSend}

                          style={{ cursor: "pointer" }}
                        >
                          Place order(prouct)
                        </NavLink>
                      </div>
                    </>
                  ) : (
                    <div className="checkout-btn">
                      <NavLink
                        className="btn-style1"
                        style={{ cursor: "pointer" }}
                        onClick={radioValue ? serviceCOD : tokenHandler}
                      >
                        Place order(Service)
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <motion.div
        animate={showResults ? "open" : "closed"}
        variants={variants}
        className={
          showResults
            ? "offcanvas all-review-offcanvas offcanvas-bottom show "
            : "offcanvas all-review-offcanvas offcanvas-bottom"
        }
        tabIndex={-1}
        id="all-review"
        aria-labelledby="all-review"
        data-bs-toggle=""
        style={{ visibility: showResults ? "visible" : "hidden" }}
        aria-hidden="true"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">Add Address Review</h5>
          <span
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setShowResults(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x"
            >
              <line x1={18} y1={6} x2={6} y2={18} />
              <line x1={6} y1={6} x2={18} y2={18} />
            </svg>
          </span>
        </div>

        <div className="offcanvas-body small">
          <form className="custom-form" onSubmit={postAddress}>
            <div className="input-box mb-3">
              <input
                type="text"
                name="pincode"
                value={UserAddress.pincode}
                onChange={handleChange}
                placeholder="postal code"
                id="form3Example3"
                className="form-control"
              />
            </div>
            <div className="input-box">
              <input
                type="text"
                name="state"
                value={UserAddress.state}
                onChange={handleChange}
                placeholder="State"
                className="form-control"
                style={{ marginTop: "17px" }}
              />
            </div>
            <div className="input-box">
              <input
                type="text"
                name="city"
                value={UserAddress.city}
                onChange={handleChange}
                placeholder="City"
                className="form-control"
              />
            </div>
            <div className="input-box">
              <input
                type="text"
                name="address"
                placeholder="Address"
                value={UserAddress.address}
                onChange={handleChange}
                className="form-control"
                style={{ marginTop: "17px" }}
              />
            </div>
            <div className="input-box">
              <input
                type="text"
                name="type"
                placeholder="Address Type Link: home office other"
                value={UserAddress.type}
                onChange={handleChange}
                className="form-control"
                style={{ marginTop: "17px" }}
              />
            </div>
          </form>
        </div>
        <div className="offcanvas-footer">
          <div className="btn-box">
            <button
              className="btn-solid font-md"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              type="submit"
              onClick={postAddress}
            >
              Add
            </button>
          </div>
        </div>
      </motion.div>
      <div
        className={
          showResults ? "modal-backdrop fade show" : "modal-backdrop fade "
        }
        style={{ display: showResults ? "block" : "none" }}
      />

      <Footer />
      <ToastContainer />
    </>
  );
}

export default CheckOutProject;
