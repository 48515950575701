import axios from "axios";


const BassURl = `https://aquamartadmin.digitalnawab.com/`
export default BassURl

export const Location_API_endpoint = `https://maps.googleapis.com/maps/api/geocode/json?`
export const Location_API_key = `AIzaSyAB1A9GDrJFvJQfnNuAAsDI-FJjuHtH9ak`
// export const Location_API_endpoint = `http://api.openweathermap.org/data/2.5/weather?`
// export const Location_API_key = `7e70d9ecb0fd9c815bd8a4222a626d8d`

// export default API_endpoint , API_key



// export const Shop = async () => {
//     try {
//         const response = await axios.get(
//             `web-api/all-shop-category`,
//         );

//         return response.data.data.results;
//     } catch (err) {
//         console.error(`Something went wrong fetching the now playing data: ${err}`);
//         throw err;
//     }
// };
// export const topproducts = async () => {
//     try {
//         const response = await axios.get(
//             `web-api/all-shop-category`,
//         );
//         console.log(response.data);
//         return response.data.results;
//     } catch (err) {
//         console.error(`Something went wrong fetching the now playing data: ${err}`);
//         throw err;
//     }
// };






