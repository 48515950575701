import axios from "axios";
import React, { useEffect, useState } from "react";
import NavbarService from "../Navbar/NavbarService";
import BassURl from "../Api/Api";
import Footer from "./Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopProduct from "../Slider/NewTopProduct";
import { NavLink, useParams } from "react-router-dom";
import NewTopProduct from "../Slider/NewTopProduct";
import { isMobile } from "react-device-detect";

import Navbar from "../Navbar/Navbar";
import CartItem from "../Cart/CartItem";
import CartService from "../Cart/CartService";
import ProductAddButton from "./ProductAddButton";
// import ServiceAdd from './ServiceAdd';
import parse from "html-react-parser";
import { PopupForm } from "../Model/PopupForm";
import { Rating } from 'react-simple-star-rating'

// import '.././index.css'

function ProductVew() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    const params = useParams();
    console.log(params);
    const [service, setService] = useState(null);

    useEffect(() => {
        axios
            .get(`${BassURl}/api/service-category`)
            .then((response) => {
                setService(response.data.data);
                console.log(response.data.data);
            });
        console.log(service);
    }, []);

    // api call
    const [testimonial, setTestimonial] = useState([]);
    useEffect(() => {
        axios.get(`${BassURl}api/all-testimonial`).then((response) => {
            setTestimonial(response.data.data);
            console.log(response.data.data);
            console.log(testimonial);
        });
    }, []);
    // const [{ user, cartShow, cartShowToast, cartItems }, dispatch] =
    //     useStateValue();
    const [items, setItems] = useState([]);

    // api call
    const [serviceData, setServiceData] = useState(null);
    useEffect(() => {
        axios.get(`${BassURl}/api/top-service`).then((response) => {
            setServiceData(response.data.data);
        });
    }, []);

    // const addtocart = (dt) => {
    //     dispatch({
    //         type: actionType.SET_CARTITEMS,
    //         cartItems: items,
    //     });

    //     localStorage.setItem("cartItems", JSON.stringify(items));
    // };

    // useEffect(() => {
    //     addtocart();
    // }, [items]);


    const [rating, setRating] = useState(4)

    // Catch Rating value
    const handleRating = (rate: num) => {
        setRating(rate)

        // other logic
    }
    // Optinal callback functions
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value: num, index: num) => console.log(value, index)

    return (
        <>
            {serviceData?.map((data, index) => {
                if (data?.service_url === params?.service_url) {
                    console.log("i am in");
                    return (
                        <>
                            <Navbar />
                            <div
                                className="image-cover hero_banner "
                                style={{
                                    background:
                                        "url(/assets/img/banner/banner.webp) no-repeat",
                                }}
                                // style={{
                                //     background:
                                //         "url(https://aquamartadmin.digitalnawab.com/assets/uploads/category/thumbnail_images/1701349880WEB-2.webp) no-repeat",
                                // }}
                                data-overlay={4}
                                key={index}
                            ></div>
                            <section
                                className="inner-section-service"
                                style={{ background: "#80808017" }}
                            >
                                <div className="container-content-service d-flex justify-content-between ">
                                    <div className="details-gallery-service">
                                        <img loading="lazy"
                                            src={
                                                BassURl +
                                                data?.service_image
                                            }
                                            style={{
                                                width: "250px",
                                                boxShadow: "0px 0px 5px grey",
                                                borderRadius: "20px"
                                            }}
                                            className=" "
                                            alt="product"
                                        />

                                    </div>
                                    <div className="details-content-service d-flex gap-0 justify-content-between">
                                        <div>
                                            <h3 className="details-name">
                                                <NavLink to="#">
                                                    {data?.service_title}
                                                </NavLink>
                                            </h3>
                                            <div className="details-meta p-0">
                                                <p className="p-0 m-0">
                                                    SKU:<span className="p-0">1234567</span>
                                                </p>
                                            </div>
                                            {/* <div className="details-rating">
                                                    <i className="active icofont-star" />
                                                    <i className="active icofont-star" />
                                                    <i className="active icofont-star" />
                                                    <i className="active icofont-star" />
                                                    <i className="icofont-star" />
                                                    <NavLink to="#">(3 reviews)</NavLink>
                                                </div> */}
                                            <div className="p-0">
                                                <Rating
                                                    className="service-rating"
                                                    onClick={handleRating}
                                                    onPointerEnter={onPointerEnter}
                                                    onPointerLeave={onPointerLeave}
                                                    onPointerMove={onPointerMove}
                                                    initialValue={rating}
                                                    size={20}

                                                /* Available Props */
                                                />
                                                <NavLink>({rating} reviews)</NavLink>
                                            </div>
                                            <h3 className="details-price">
                                                {data?.service_mrp && (
                                                    <>
                                                        <del
                                                            style={{
                                                                fontSize:
                                                                    "22px",
                                                            }}
                                                        >
                                                            ₹{" "}
                                                            {
                                                                data?.service_mrp
                                                            }
                                                            {/* <small></small> */}
                                                        </del>{" "}
                                                        &nbsp;
                                                    </>
                                                )}
                                                <span
                                                    style={{
                                                        color: "#0e76a8",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    ₹ {data?.service_price}
                                                    <small></small>
                                                </span>
                                            </h3>
                                            <div className=" ">
                                                <div >
                                                    <h5>
                                                        Offers : <span style={{
                                                            fontSize: "18px",
                                                            fontWeight: "400"
                                                        }}>{data?.offer}% Discount</span>
                                                    </h5>
                                                </div>
                                                <div className="">
                                                    <h5>
                                                        Duration : <span style={{
                                                            fontSize: "18px",
                                                            fontWeight: "400"
                                                        }}>{data?.time}</span>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <p className="details-desc">
                                                <ul
                                                    style={{
                                                        listStyle: "disc",
                                                    }}
                                                >
                                                    {parse(
                                                        data?.description.slice(
                                                            0,
                                                            2000
                                                        )
                                                    )}
                                                    <h4
                                                        style={{
                                                            color: "#0e76a8",
                                                        }}
                                                    >
                                                        {/* Key Features: */}
                                                    </h4>

                                                    {/* <li className="bullet">
                                                            {parse(
                                                                data?.key_features
                                                            )}
                                                        </li> */}

                                                    {/* <ul>
                                                            {data?.key_features
                                                                ?.replace(
                                                                    /<[^>]+>/g,
                                                                    ""
                                                                )
                                                                .replace(
                                                                    /&nbsp;/g,
                                                                    " "
                                                                )
                                                                .trim()
                                                                .split("\n")
                                                                .filter(
                                                                    (feature) =>
                                                                        feature.trim() !==
                                                                        ""
                                                                )
                                                                .map(
                                                                    (
                                                                        feature,
                                                                        index
                                                                    ) => (
                                                                        <li
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="bullet"
                                                                        >
                                                                            {feature.trim()}
                                                                        </li>
                                                                    )
                                                                )}
                                                        </ul> */}

                                                    {/* <li>Mu                          ltiple purification processes: RO, UV, UF, and TDS control</li>
                                                        <li>Detachable storage tank: 8 liters capacity for cleaning</li>
                                                        <li>Mineral RO technology: Makes water 100% pure and rich in minerals</li>
                                                        <li>Detachable storage tank: 8 liters capacity for cleaning</li>
                                                        <li>Retains Essential Minerals, , ABS Food Grade Plastic, UV lamp alarm</li> */}
                                                </ul>
                                            </p>

                                            <div className="details-list-group">
                                                <label className="details-list-title">
                                                    Share:
                                                </label>
                                                <ul className="details-share-list">
                                                    <li>
                                                        <NavLink
                                                            to="#"
                                                            className="icofont-facebook"
                                                            title="Facebook"
                                                        />
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            to="#"
                                                            className="icofont-twitter"
                                                            title="Twitter"
                                                        />
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            to="#"
                                                            className="icofont-linkedin"
                                                            title="Linkedin"
                                                        />
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            to="#"
                                                            className="icofont-instagram"
                                                            title="Instagram"
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                            <ProductAddButton data={data} />

                                        </div>

                                        {/* <PopupForm /> */}
                                    </div>
                                </div>
                            </section>

                            {/* <section
                                className="inner-section"
                                style={{
                                    background: "rgba(128, 128, 128, 0.09)",
                                }}
                            >
                                <div className="container desTop">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <ul className="nav nav-tabs">
                                                <li>
                                                    <NavLink
                                                        to="#tab-desc"
                                                        className="tab-link active"
                                                        data-toggle="tab"
                                                    >
                                                        descriptions
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        to="#tab-spec"
                                                        className="tab-link"
                                                        data-toggle="tab"
                                                    >
                                                        Specifications
                                                    </NavLink>
                                                </li>
                                               
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade show active"
                                        id="tab-desc"
                                    >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="product-details-frame">
                                                    <div className="tab-descrip">
                                                        {parse(
                                                            data?.description
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="tab-spec"
                                    >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="product-details-frame">
                                                   
                                                    {data &&
                                                        data.tech_specification && (
                                                            <div>
                                                                {parse(
                                                                    data.tech_specification
                                                                )}
                                                            </div>
                                                        )}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="tab-reve"
                                    >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="product-details-frame">
                                                    <ul className="review-list">
                                                        <li className="review-item">
                                                            <div className="review-media">
                                                                <NavLink
                                                                    className="review-avatar text-center"
                                                                    to="#"
                                                                >
                                                                    <img loading="lazy"
                                                                        src="https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector-PNG-File.png"
                                                                        className="logo"
                                                                    />
                                                                </NavLink>
                                                                <h5 className="review-meta">
                                                                    <NavLink to="#">
                                                                        Navneet
                                                                    </NavLink>
                                                                    <span>
                                                                        June 02,
                                                                        2020
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                            <ul className="review-rating">
                                                                ~
                                                                <li className="icofont-ui-rating" />
                                                                <li className="icofont-ui-rating" />
                                                                <li className="icofont-ui-rating" />
                                                                <li className="icofont-ui-rating" />
                                                                <li className="icofont-ui-rate-blank" />
                                                            </ul>
                                                            <p className="review-desc">
                                                                Lorem ipsum
                                                                dolor sit amet
                                                                consectetur
                                                                adipisicing
                                                                elit. Ducimus
                                                                hic amet qui
                                                                velit, molestiae
                                                                suscipit
                                                                perferendis,
                                                                autem doloremque
                                                                blanditiis
                                                                dolores nulla
                                                                excepturi ea
                                                                nobis!
                                                            </p>
                                                            <form className="review-reply">
                                                                <input
                                                                    type="text"
                                                                    placeholder="reply your thoughts"
                                                                />
                                                                <button>
                                                                    <i className="icofont-reply" />
                                                                    reply
                                                                </button>
                                                            </form>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section> */}

                            <Footer />
                            {/* {cartShow && <CartItem />} */}
                        </>
                    );
                }
            })}
        </>
    );
}

export default ProductVew;
