import React, { useContext } from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToCartAction, addToServiceCartAction } from '../Redux/Action/CartAction';
import { ToastContainer, toast } from 'react-toastify';
import MyContext from '../context/MyContext';

function ProductAddButton(data) {
    const context = useContext(MyContext)
const {setIsServiceCartAdded} = context

    console.log(data)
    const dispatch = useDispatch();
    const [qty, setCount] = useState(1);
    const handleIncrement = () => {

        if (qty < 5) {

            setCount((prevCount) => prevCount + 1);
        }
    };

    //Create handleDecrement event handler
    const handleDecrement = () => {
        if (qty > 1) {
            setCount((prevCount) => prevCount - 1);
        }
    };

    // add to cart 

    const showToastError = () => {

        
        toast.error("Select date and time", {
            position: "top-center"
        });


        
    }
    const showToastSuccess = () => {
        toast.success("Added to cart Successfully",{
            position: "top-center"
        })

        
    }
    function addtocart() {
        if (data.date === null || data.time === null) {
            showToastError()
            // toast.error("Select date and time")
        }
        else {
            if (data.data.product_title) {
                dispatch(addToCartAction(data.data, qty));
                // showToastSuccess()
            }
            if (data.data.service_title) {
                dispatch(addToServiceCartAction(data.data, qty));
                // setIsServiceCartAdded(true)
                showToastSuccess()
                setTimeout(() => {
                    data.click(false)
                }, 1800);
            }
        }
    }

    const curretUser = JSON.parse(window.localStorage.getItem('currentUser'))
    return (
        <>

            <div className="details-add-group mt-3">
                <button className="product-add" title="Add to Cart"

                    onClick={addtocart}
                >
                    <i className="fa fa-shopping-basket" />
                    <span>add to cart</span>
                </button>

            </div>

            <ToastContainer />

        </>
    )
}

export default ProductAddButton