import React from 'react'
import { useParams } from 'react-router-dom'

function AppliancesItemDetailPage() {
  const params = useParams();
  return (
    <div>AppliancesItemDetailPage</div>
  )
}

export default AppliancesItemDetailPage